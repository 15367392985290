<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <header id="home">
        <!-- Background Image -->
        <div class="bg-img" style="background-image: url('./img/handshake0.jpg');">
            <div class="overlay"></div>
        </div>
        <!-- /Background Image -->

        <!-- Nav -->
        <nav id="nav" class="navbar nav-transparent fixed-nav" style="background-color: #fff; opacity: 90%;">
            <div class="container">

                <div class="navbar-header">
                    <router-link to="/" class="text-decoration-none">
                    <!-- Logo -->
                    <div class="navbar-brand">
                        <h2><a>홍현필 변호사 사무소</a></h2>
                    </div></router-link>
                    <!-- /Logo -->

                    <!-- Collapse nav button -->
                    <div class="nav-collapse">
                        <span></span>
                    </div>
                    <!-- /Collapse nav button -->
                </div>

                <!-- Main navigation -->
                <ul class="main-nav nav navbar-nav navbar-right">
                    <!-- <li> <a href="#home">법인회생</a> </li> -->
                    <!-- <li> <a href="#about">법인회생 절차</a> </li> -->
                    <li>
                        <a href="#about" style="color:#5f5f5f"><b>법인회생</b></a>
                    </li>
                    <li>
                        <a href="#service" style="color:#5f5f5f"><b>법인회생 절차</b></a>
                    </li>
                    <li>
                        <a href="#team" style="color:#5f5f5f"><b>주요업무</b></a>
                    </li>
                    <!-- <li>
                        <a href="#team">Team</a>
                    </li> -->
                    <li class="has-dropdown">
                        <a href="#blog" style="color:#5f5f5f"><b>블로그</b></a>
                        <ul class="dropdown">
                            <li>
                                <a href="https://cafe.naver.com/honglaw">바로가기</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#contact" style="color:#6195FF"><b>무료상담</b></a>
                    </li>
                </ul>
                <!-- /Main navigation -->

            </div>
        </nav>
        <!-- /Nav -->

        <!-- home wrapper -->
        <div class="home-wrapper">
            <div class="container">
                <div class="row">

                    <!-- home content -->
                    <div class="col-md-10 col-md-offset-1">
                        <div class="home-content">
                            <h1 class="white-text">법인회생 ㆍ파산 전문</h1>
                            <p class="white-text">법인회생 홍현필 변호사 사무소에서 새로운 시작을!
                            </p>
                            <a href="#about"><button class="white-btn">법인회생ㆍ파산이란?</button></a>
                            <a href="#contact"><button class="main-btn">&nbsp;&nbsp;&nbsp;무료 상담하기&nbsp;&nbsp;&nbsp;</button></a>
                        </div>
                    </div>
                    <!-- /home content -->

                </div>
            </div>
        </div>
        <!-- /home wrapper -->

    </header>
</template>

<script setup="setup">

</script>

<style>
#nav.fixed-nav a href{
    position: fixed;
    left: 0;
    right: 0;
    padding: 0px 0px;
    opacity: 1 !important;
    color: black !important;
    border-bottom: 2px solid #EEE;
}
.navbar-brand h2 {
    padding: 2% 0px !important;
    color: #6195FF !important;
    opacity: 1 !important;
}

.navbar-brand a:hover{
    color: #6195FF !important;
    opacity: 1 !important;
}
</style>