<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!-- About -->
    <div id="about" class="section md-padding">

        <!-- Container -->
        <div class="container">

            <!-- Row -->
            <div class="row">

                <!-- Section header -->
                <div class="section-header text-center">
                    <h2 class="title">법인회생ㆍ파산이란?</h2>
                </div>
                <!-- /Section header -->

                <!-- about -->
                <div class="col-md-6">
                    <div class="about">
                        <i class="fa fa-cogs"></i>
                        <h3>법인회생</h3>
                        <p>회사 운영의 어려움으로 파탄의 상황에 직면한 법인에 대해 사업을 계속할 때의 가치가 사업을 청산할 때의 가치보다 크다고 인정될 경우 법원에
                            감독하에 법인의 생생을 도모하는 제도</p>
                        <br>
                            <br>
                                <!-- <a href="#">Read more</a> -->
                            </div>
                        </div>
                        <!-- /about -->

                        <!-- about -->
                        <div class="col-md-6">
                            <div class="about">
                                <i class="fa fa-magic"></i>
                                <h3>법인파산</h3>
                                <p>재정적 어려움으로 인하여 파탄에 직면해 있는 채무자에 대하여 채권자. 주주. 지분권자 등 이해관계인의 법률관계를 조정하여 채무자 또는 그
                                    사업의 효율적인 회생을 도모하거나, 회생절차가 중도에 폐지되었거나 회생신청의 자격이 되지 않은 채무자의 재산을 공정하게 환가배당 하는 것을 목적</p>
                                <!-- <a href="#">Read more</a> -->
                            </div>
                        </div>
                        <!-- /about -->
                    </div>
                    <!-- /Row -->

                </div>
                <!-- /Container -->

            </div>
            <!-- /About -->

            <!-- Portfolio -->
            <div id="portfolio" class="section md-padding bg-grey">

                <!-- Container -->
                <div class="container">

                    <!-- Row -->
                    <div class="row">

                        <!-- Section header -->
                        <div class="section-header text-center">
                            <h2 class="title">신청대상</h2>
                            <br>
                                <br>
                                    <br>

                                        <!-- Page Features-->
                                        <div class="row gx-lg-5">
                                            <div class="col-lg-6 col-xxl-4 mb-5">
                                                <div class="card bg-light border-0 h-100">
                                                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                                        <div class="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </div>
                                                        <h3 class="fs-4 fw-bold">채무과다로 파산원인이 되는 기업</h3>
                                                        <p class="mb-0">채무과다로 인해 파산의 원인이 되는 사실이 생길 우려가 있는 경우</p>
                                                        <br></div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xxl-4 mb-5">
                                                    <div class="card bg-light border-0 h-100">
                                                        <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                                            <div class="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                                                <i class="fa-solid fa-minus"></i>
                                                            </div>
                                                            <h3 class="fs-4 fw-bold">실시적인 재정적 어려움</h3>
                                                            <p class="mb-0">계속적인 영업이익이 있으나 재정적 어려움을 겪는 기업</p>
                                                            <br></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-xxl-4 mb-5">
                                                        <div class="card bg-light border-0 h-100">
                                                            <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                                                <div class="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                                                    <i class="fa-solid fa-minus"></i>
                                                                </div>
                                                                <h3 class="fs-4 fw-bold">사업확장으로 운영이 어려운 기업</h3>
                                                                <p class="mb-0">신규투자 혹은 사업확장으로 인하여 재정적 어려움을 겪는 기업</p>
                                                                <br></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-xxl-4 mb-5">
                                                            <div class="card bg-light border-0 h-100">
                                                                <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                                                        <i class="fa-solid fa-minus"></i>
                                                                    </div>
                                                                    <h3 class="fs-4 fw-bold">정상적인 운영이 어려운 기업</h3>
                                                                    <p class="mb-0">채무로 인한 추심 및 강제집행 등으로 인한 정상적인 운영이 어려워진 경우</p>
                                                                    <br></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <!-- /Section header -->
                                                </div>
                                                <!-- /Row -->

                                            </div>
                                            <!-- /Container -->

                                        </div>
                                        <!-- /Portfolio -->
                                        <!-- Service -->
                                        <div id="service" class="section md-padding">

                                            <!-- Container -->
                                            <div class="container">

                                                <!-- Row -->
                                                <div class="row">

                                                    <!-- Section header -->
                                                    <div class="section-header text-center">
                                                        <h2 class="title">법인회생 절차</h2>
                                                    </div>
                                                    <!-- /Section header -->

                                                    <!-- service -->
                                                    <div class="col-md-4 col-sm-6">
                                                        <div class="service">
                                                            <i class="fa-solid fa-1"></i>
                                                            <h3>회생절차 개시신청</h3>
                                                            <p>보관처분, 포괄적 금지명령 등 신청</p>
                                                            <br></div>
                                                        </div>
                                                        <!-- /service -->

                                                        <!-- service -->
                                                        <div class="col-md-4 col-sm-6">
                                                            <div class="service">
                                                                <i class="fa-solid fa-2"></i>
                                                                <h3>심사</h3>
                                                                <p>대표자 심문 · 현장검증 등
                                                                </p>
                                                                <br></div>
                                                            </div>
                                                            <!-- /service -->

                                                            <!-- service -->
                                                            <div class="col-md-4 col-sm-6">
                                                                <div class="service">
                                                                    <i class="fa-solid fa-3"></i>
                                                                    <h3>개시결정</h3>
                                                                    <p>기업의 재무 건전성 등을 조합적으로 고려</p>
                                                                </div>
                                                            </div>
                                                            <!-- /service -->

                                                            <!-- service -->
                                                            <div class="col-md-4 col-sm-6">
                                                                <div class="service">
                                                                    <i class="fa-solid fa-4"></i>
                                                                    <h3>채권신고 · 조사</h3>
                                                                    <p>회생채권 등 목록 제출 채권신고 / 조사 및 조사위원에 의한 조사</p>
                                                                    <br></div>
                                                                </div>
                                                                <!-- /service -->

                                                                <!-- service -->
                                                                <div class="col-md-4 col-sm-6">
                                                                    <div class="service">
                                                                        <i class="fa-solid fa-5"></i>
                                                                        <h3>관리인 보고를 위한 관계인 집회</h3>
                                                                        <p>주요사항 요지의 통지, 관계인 설명회 등</p>
                                                                    </div>
                                                                </div>
                                                                <!-- /service -->

                                                                <!-- service -->
                                                                <div class="col-md-4 col-sm-6">
                                                                    <div class="service">
                                                                        <i class="fa-solid fa-6"></i>
                                                                        <h3>회생인가 결정</h3>
                                                                        <p>이후 제2,3회 관계인 집회를 가진뒤 최종적으로 동의를 얻어 회생인가 결정</p>
                                                                    </div>
                                                                </div>
                                                                <!-- /service -->
                                                            </div>
                                                            <!-- /Row -->

                                                        </div>
                                                        <!-- /Container -->

                                                    </div>
                                                    <!-- /Service -->

                                                    <!-- Why Choose Us -->
                                                    <div id="features" class="section md-padding bg-grey">

                                                        <!-- Container -->
                                                        <div class="container">

                                                            <!-- Row -->
                                                            <div class="row">
                                                                <!-- why choose us content -->
                                                                <div class="col-md-6">
                                                                    <div class="section-header">
                                                                        <h2 class="title">법인회생은 왜 필요한가요?
                                                                        </h2>
                                                                    </div>
                                                                    <p >법인(기업)회생절차를 통하여 채무자의 재건을 도모할 수 있습니다.</p>
                                                                    <div class="feature">
                                                                        <i class="fa fa-check"></i>
                                                                        <p>법인에게 경제적 재기의 기회를 제공</p>
                                                                    </div>
                                                                    <div class="feature">
                                                                        <i class="fa fa-check"></i>
                                                                        <p>원칙적으로 경영권을 보장</p>
                                                                    </div>
                                                                    <div class="feature">
                                                                        <i class="fa fa-check"></i>
                                                                        <p>강제집행, 가압류, 가처분, 경매 등의 중지 및 금지</p>
                                                                    </div>
                                                                    <div class="feature">
                                                                        <i class="fa fa-check"></i>
                                                                        <p>채무의 일부 면제 및 감액</p>
                                                                    </div>
                                                                    <div class="feature">
                                                                        <i class="fa fa-check"></i>
                                                                        <p>채권자들은 파산절차에서보다 더 많은 변제 가능</p>
                                                                    </div>
                                                                </div>
                                                                <!-- /why choose us content -->

                                                                <!-- About slider -->
                                                                <div class="col-md-6">
                                                                    <div id="about-slider" class="owl-carousel owl-theme">
                                                                        <img class="img-responsive" src="/img/scss.jpg" alt=""></div>
                                                                    </div>
                                                                    <!-- /About slider -->

                                                                </div>
                                                                <!-- /Row -->

                                                            </div>
                                                            <!-- /Container -->

                                                        </div>
                                                        <!-- /Why Choose Us -->

                                                        <!-- Testimonial -->
                                                        <div id="testimonial" class="section md-padding">

                                                            <!-- Background Image -->
                                                            <div class="bg-img" style="background-image: url('./img/handshake.png');">
                                                                <div class="overlay"></div>
                                                            </div>
                                                            <!-- /Background Image -->

                                                            <!-- Container -->
                                                            <div class="container">
                                                                <!-- Row -->
                                                                <div class="row">

                                                                    <!-- Testimonial slider -->
                                                                    <div class="col-md-10 col-md-offset-1">
                                                                        <div id="testimonial-slider" class="owl-carousel owl-theme">

                                                                            <!-- testimonial -->
                                                                            <div class="testimonial ">
                                                                                <div
                                                                                    class="call_to_action_box d-md-flex justify-content-between align-items-center">
                                                                                    <div class="call_to_action_content">
                                                                                        <a class="lead">
                                                                                            무료로 상담을 받으시고 지금 당장 고통에서 벗어나세요.
                                                                                        </a>
                                                                                        <p class="text-light">
                                                                                            더 이상 망설이지 말고 새로운 시작을 하세요!
                                                                                        </p>
                                                                                    </div>
                                                                                    <div class="call_to_action_btn">
                                                                                        <a href="#contact" rel="nofollow" class="main-btn">지금 바로 문의하기</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- /testimonial -->

                                                                        </div>
                                                                    </div>
                                                                    <!-- /Testimonial slider -->

                                                                </div>
                                                                <!-- /Row -->

                                                            </div>
                                                            <!-- /Container -->

                                                        </div>
                                                        <!-- /Testimonial -->

                                                        
                                                        <div id="plan" class="section md-padding">
                                                            <div class="section-header text-center">
                                                                <h2 class="title">기업회생 절차기간</h2>
                                                            </div>
                                                                <Plan></Plan>
                                                        </div>

                                                        <div id="plan" class="section md-padding">
                                                            <div class="section-header text-center">
                                                                <h2 class="title">기업회생 Q/A</h2>
                                                            </div>
                                                            <Faq></Faq>
                                                        </div>

                                                        <!-- Team -->
                                                        <div id="team" class="section md-padding">

                                                            <!-- Container -->
                                                            <div class="container">

                                                                <!-- Row -->
                                                                <div class="row">

                                                                    <!-- Section header -->
                                                                    <div class="section-header text-center">
                                                                        <h2 class="title">주요업무</h2>
                                                                    </div>
                                                                    <br>
                                                                        <br>
                                                                            <!-- /Section header -->

                                                                            <!-- team -->
                                                                            <div class="col-sm-6">
                                                                                <div class="team">
                                                                                    <div class="team-img">
                                                                                        <div class="overlay"></div>
                                                                                    </div>
                                                                                    <div class="team-content">
                                                                                        <h3>법인(기업)</h3>
                                                                                        <span>회생ㆍ파산</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- /team -->

                                                                            <!-- team -->
                                                                            <div class="col-sm-6">
                                                                                <div class="team">
                                                                                    <div class="team-img">
                                                                                        <div class="overlay"></div>
                                                                                    </div>
                                                                                    <div class="team-content">
                                                                                        <h3>개인</h3>
                                                                                        <span>회생ㆍ파산</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- /team -->

                                                                        </div>
                                                                        <!-- /Row -->

                                                                    </div>
                                                                    <!-- /Container -->

                                                                </div>
                                                                <!-- /Team -->
                                                                <!-- Blog -->
                                                                <div id="blog" class="section md-padding bg-grey">

                                                                    <!-- Container -->
                                                                    <div class="container">

                                                                        <!-- Row -->
                                                                        <div class="row">

                                                                            <!-- Section header -->
                                                                            <div class="section-header text-center">
                                                                                <h2 class="title">고객 후기 Review</h2>
                                                                            </div>
                                                                            <!-- /Section header -->

                                                                            <!-- blog -->
                                                                            <div class="col-md-4">
                                                                                <div class="blog">
                                                                                    <div class="blog-img"></div>
                                                                                    <div class="blog-content">
                                                                                        <ul class="blog-meta">
                                                                                            <li>
                                                                                                <i class="fa fa-user"></i>김 * 영</li>
                                                                                        </ul>
                                                                                        <h3>덕분에 잘 해결되었습니다!</h3>
                                                                                        <br>
                                                                                            <p>사실 이자 탕감만 생각하고 있었는데 원금도 많이 탕감되어 정말 놀랐어요. 고민만 하지말고 더 일찍이 신청하게 되었다면 좋았겠지만 생각했던
                                                                                                금액보다 훨씬 줄어서 너무 좋습니다. 정말 감사하다는 인사드리고 싶습니다.</p>
                                                                                            <br></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- /blog -->

                                                                                    <!-- blog -->
                                                                                    <div class="col-md-4">
                                                                                        <div class="blog">
                                                                                            <div class="blog-img"></div>
                                                                                            <div class="blog-content">
                                                                                                <ul class="blog-meta">
                                                                                                    <li>
                                                                                                        <i class="fa fa-user"></i>김 * 준</li>
                                                                                                </ul>
                                                                                                <h3>더 늦지 않게 신청해서 다행이라 생각합니다</h3>
                                                                                                <p>처음 상담들으면서 어떻게 해나가야할지 설명도 듣고 힘들었던 일들 말씀도 드렸던게 얼마 안된 것 같은데 벌써 2년이란 시간이 흘렀고 열심히
                                                                                                    변제중입니다. 제 소득보다 더 많은 금액이 빚 갚는 곳으로 나가고 있다가 이젠 생활비 확보도 하고 일상이 생겨서 너무 좋습니다.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- /blog -->

                                                                                    <!-- blog -->
                                                                                    <div class="col-md-4">
                                                                                        <div class="blog">
                                                                                            <div class="blog-img"></div>
                                                                                            <div class="blog-content">
                                                                                                <ul class="blog-meta">
                                                                                                    <li>
                                                                                                        <i class="fa fa-user"></i>박 * 윤</li>
                                                                                                </ul>
                                                                                                <h3>너무 친절하고요, 정말 감사합니다.</h3>
                                                                                                <p>회생 해보자고 해서 못이기는 마음에 신청했더니 바로 됐습니다. 아무것도 없지만 다시 시작한다는 마음으로 하려고 합니다. 정말 감사드립니다!</p>
                                                                                                <br>
                                                                                                    <br>
                                                                                                        <br></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!-- /blog -->

                                                                                            </div>
                                                                                            <!-- /Row -->

                                                                                        </div>
                                                                                        <!-- /Container -->

                                                                                    </div>
                                                                                    <!-- /Blog -->

                                                                                    <!-- Contact -->
                                                                                    <div id="contact" class="section md-padding">

                                                                                        <!-- Container -->
                                                                                        <div class="container">

                                                                                            <!-- Row -->
                                                                                            <div class="row">

                                                                                                <!-- Section-header -->
                                                                                                <div class="section-header text-center">
                                                                                                    <h2 class="title">무료 상담하기</h2>
                                                                                                </div>
                                                                                                <!-- /Section-header -->

                                                                                                <!-- contact -->
                                                                                                <div class="col-sm-4">
                                                                                                    <div class="contact">
                                                                                                        <a href="tel:01042428700">
                                                                                                        <i class="fa fa-phone"></i>
                                                                                                        <h3>Phone</h3>
                                                                                                        <p>010-4242-8700</p></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!-- /contact -->

                                                                                                <!-- contact -->
                                                                                                <div class="col-sm-4">
                                                                                                    <div class="contact">
                                                                                                        <a href="mailto:gaja8700@naver.com">
                                                                                                        <i class="fa fa-envelope"></i>
                                                                                                        <h3>Email</h3>
                                                                                                        <p>gaja8700@naver.com</p></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!-- /contact -->

                                                                                                <!-- contact -->
                                                                                                <div class="col-sm-4">
                                                                                                    <div class="contact">
                                                                                                        <i class="fa fa-map-marker"></i>
                                                                                                        <h3>Address</h3>
                                                                                                        <p>서울 서초구 서초대로51길 25</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!-- /contact -->

                                                                                                <!-- contact form -->
                                                                                                <Contact></Contact>
                                                                                                <!-- /contact form -->

                                                                                            </div>
                                                                                            <!-- /Row -->

                                                                                        </div>
                                                                                        <!-- /Container -->

                                                                                    </div>
                                                                                    <!-- /Contact -->

                                                                                </template>

                                                                                <script>
                                                                                    import Contact from '../components/Contact.vue'
                                                                                    import Faq from '../components/Faq.vue'
                                                                                    import Plan from '../components/Plan.vue'
                                                                                    import {ref} from 'vue'

                                                                                    export default {
                                                                                        name: 'HelloWorld',
                                                                                        props: {
                                                                                            msg: String
                                                                                        },
                                                                                        components: {
                                                                                            Contact,
                                                                                            Faq,
                                                                                            Plan
                                                                                        },
                                                                                        setup() {
                                                                                            const email = ref('')
                                                                                            const password = ref('')
                                                                                            const error = ref(null)

                                                                                            return {email, password, error}
                                                                                        }
                                                                                    }
                                                                                </script>

                                                                                <!-- Add "scoped" attribute to limit CSS to this component only -->
                                                                                <style>
                                                                                    :hover call_to_action_btn {
                                                                                        opacity: 100%;
                                                                                    }
                                                                                </style>