<!-- eslint-disable vue/multi-word-component-names -->
<template>
   <table class="table">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-1" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">1. 보전처분, 포괄적금지명령, 보정명령, 예납명령 2. 대표자심문 3. 개시결정 등의 단계로 진행되고 <b style="color: red;">약 1개월</b> 정도 소요됩니다.</td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-2" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">1. 채권자 목록 제출 2. 시부인표 3. 조사보고서, 관리인보고서 제출 4. 채권자 집회 5. 인가결정 등의 단계로 진행되고 <b style="color: red;">약 최소 5개월~최대 8개월</b>정도 소요됩니다.</td>
    </tr>
    <tr>
      <th scope="row"><i class="fa-solid fa-3" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">변제기간은 <b style="color: red;">10년 기간</b> 동안 채권자에게 매년 <b style="color: red;">분할납부</b>하는 방법으로 변제계획을 수행합니다.</td>
     
    </tr>
  </tbody>
</table>
</template>
<script></script>
<style>
.table {
    color: black;
    width: 60%;
    border:0px solid black;margin-left:auto;margin-right:auto;
    font-size: larger  !important;

}

.table th{
    text-align: center;
    border-bottom: 0px solid gray !important;
}

.table td{
    text-align: center;
    border-bottom: 1px solid gray !important;

}
@media screen and (max-width:767px) {
/* 모바일 */
.table {
    width: 90% !important;
}}
</style>