<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!-- Footer -->
    <footer id="footer" class="sm-padding bg-dark">
        <!-- Container -->
        <div class="container">

            <!-- Row -->
            <div class="row">

                <div class="col-md-12">

                        <!-- footer copyright -->
                        
                        <div class="footer-copyright">
                            <p>상호 : 홍현필 변호사 사무소 | 사업자등록번호 : 264-81-01728 | 사업장 주소 : 서울 서초구 서초대로51길 25, 802호
                            </p>
                            <p>Copyright ⓒ 2023 홍현필 변호사 사무소. All Rights Reserved.
                            </p>
                        </div>
                        <!-- /footer copyright -->

                    </div>

                </div>
                <!-- /Row -->

            </div>
            <!-- /Container -->

        </footer>
        <!-- /Footer -->
        
	<div id="back-to-top"></div>
	<!-- /Back to top -->
    </template>

    <script>
        export default {
            
        }
    </script>

    <style></style>