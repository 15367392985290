<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="col-md-8 col-md-offset-2">
        <br>
            <br>
                <br>

                    <form
                        class="gform"
                        action="https://script.google.com/macros/s/AKfycbzEgMVvVe6FA9OIufJ059a0Yw_VDReS791iD5kqC25kjZotC26dX1LB00DALKDVVtxH/exec"
                        method="POST"
                        data-email="gaja8700@naver.com">
                        <fieldset>
                            <div class="box-input">
                                <label for="userName">이름</label>
                                <input
                                    type="text"
                                    id="userName"
                                    name="이름"
                                    placeholder="이름 (*필수)"
                                    maxlength="100"
                                    required="required"></div>
                                <div class="box-input">
                                    <label for="userPhone">연락처</label>
                                    <input
                                        type="text"
                                        id="userPhone"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                                        name="연락처"
                                        placeholder="연락처 (*필수)"
                                        maxlength="100"
                                        required="required"></div>
                                    <div class="box-input">
                                        <label for="userEmail">이메일</label>
                                        <input
                                            type="email"
                                            id="userEmail"
                                            name="이메일"
                                            placeholder="이메일 (*필수)"
                                            maxlength="100"
                                            required="required"></div>
                                        <div class="box-input">
                                            <label for="userMessage">상담내용</label>
                                            <textarea
                                                name="상담내용"
                                                id="userMessage"
                                                class="border border-white"
                                                maxlength="300"></textarea>
                                        </div>

                                        <div class="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="aggrement"
                                                required="required">
                                                <label class="custom-control-label" for="aggrement">&nbsp;개인정보 수집 및 이용에 동의합니다.</label>                          
                                                    <a @click="handleClick" style="color: darkgray; font-size: small;">&nbsp;&nbsp;자세히보기</a>
                                                    <ConfirmationModal ref="modal" :content="modalContent"/>
                                             
                                            </div>

                                            <div class="container">

                                                <div class="col-md-offset-2">
                                                    <button type="submit" class="contact-btn">무료상담 신청</button>

                                                </div>
                                            </div>
                                        </fieldset>
                                        <br>
                                            <br>
                                                <div style="display:none" class="thankyou_message">
                                                    <!-- You can customize the thankyou message by editing the code below -->
                                                    <b>
                                                        <p id="message_text" class="text-center">문의가 접수되었습니다. 빠른 시간 내에 연락드리도록 하겠습니다.</p>
                                                    </b>

                                                </div>
                                            </form>
                                        </div>
                                    </template>

                                    <script>
                                        import {ref} from "vue";
                                        import ConfirmationModal from "../components/ConfirmationModal.vue";
                                        export default {
                                            name: "App",
                                            components: {
                                                ConfirmationModal
                                            },
                                            setup() {
                                                const modal = ref(null);
                                                const modalContent = ref(["개인정보 수집 및 이용안내", "개인정보 수집주체 : 홍현필 변호사 사무소",
                                            "개인정보 수집항목 : 이름, 연락처, 이메일, IP등을 포함한 고객이 입력한 정보",
                                            "개인정보 수집 이용목적 : 전화, SMS를 통한 상품안내 및 상담",
                                            "개인정보보유/이용기간 : 수집일로부터 1년(고객동의 철회시 지체없이 파기)",
                                            " ",
                                            "개인정보의 취급 위탁",
                                            "당사는 서비스 이행 및 향상을 위해 개인정보 취급업무를 전문업체에 위탁 운영하고 있습니다. 또한 개인정보를 안전하게 처리하기 위하여 필요한 사항등을 명확히 규정하고 있으며, 당해 계약 내용을 서면 또는 전자적으로 보관하고 있습니다.",                                     
                                            ]);
                                                const result = ref("");

                                                // async-await을 사용하여, Modal로부터 응답을 기다리게 된다.
                                                const handleClick = async () => {
                                                    const ok = await modal
                                                        .value
                                                        .show();
                                                    if (ok) {
                                                        result.value = "확인을 눌렀군요!";
                                                    } else {
                                                        result.value = "취소를 눌렀네요?";
                                                    }
                                                };
                                                return {modal, modalContent, handleClick};
                                            }
                                        };
                                    </script>

                                    <style>
                                        #message_text {
                                            color: #FE5949;
                                        }

                                        .contact-btn {
                                            background: #6195FF;
                                            color: #FFF;
                                            display: inline-block;
                                            margin: 3px;
                                            border: 2px solid transparent;
                                            border-radius: 3px;
                                            -webkit-transition: 0.2s opacity;
                                            transition: 0.2s opacity;
                                            width: 40%;
                                            height: 50px;
                                            scrollbar-width:auto;

                                        }

                                        .contact-btn:hover {
                                            opacity: 0.8;
                                        }

                                        @media only screen and (max-width: 991px) {

                                            .contact-btn {
                                                display: inline-block;
                                                padding: 10px 20px;
                                                font-size: 16px;
                                                cursor: pointer;
                                                text-align: center;
                                                width: 100%;
                                                height: 50px;

                                            }
                                        }
                                    </style>