<template>
    <Header></Header>
    <router-view />
    <Footer></Footer>
</template>

<script>

    import Header from './components/Header.vue'
    import Footer from './components/Footer.vue'
    export default {
        name: 'App',
        components: {

            Header,
            Footer
        },
        watch: {
            $route() {
                window.scrollTo(0, 0);
            }
        }
    }
</script>

<style></style>