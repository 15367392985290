<!-- eslint-disable vue/multi-word-component-names -->
<template>
   <table class="table">
  <thead>

  </thead>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-q" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;"><b>법인회생은 어느정도 가능성이 있나요?</b></td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-a" style="color: cornflowerblue;"></i></th>
      <td colspan="3" style="text-align: left;">일반적으로 법인회생 인가율은  <b style="color: red;">평균 30%</b> 내외정도입니다.</td>
    </tr>
    <tr>
    </tr>
  </tbody>

<br>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-q" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;"><b>법인회생을 인가받은 경우 부채 탕감이 되나요?</b></td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-a" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">평균적으로 <b style="color: red;">신용채권의 경우 30%~70%</b> 탕감이 있습니다</td>
    </tr>
    <tr>
    </tr>
  </tbody>

  <br>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-q" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;"><b>법인회생은 신청조건이 어떻게 되나요?</b></td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-a" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">법인회생에 경우 청산가치보다 계속가치가 높을 경우와 변제수행 가능성이 존재해야합니다.</td>
    </tr>
    <tr>
    </tr>
  </tbody>

  <br>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-q" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;"><b>채권자들이 예금, 입차보증금을 가압류한 경우 언제 해지가 가능한가요?</b></td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-a" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">개시결정 후 법원의 허가를 받아 해제절차를 진행할 수 있습니다.</td>
    </tr>
    <tr>
    </tr>
  </tbody>

  <br>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-q" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;"><b>법인 회생은 기간은 얼마나 소요되나요?</b></td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-a" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">개시결정까지 1개월 소요되며, 인가결정까지는 <b style="color: red;">최소 5개월 ~ 최대 8개월</b>이 소요됩니다.</td>
    </tr>
    <tr>
    </tr>
  </tbody>

  <br>
  <tbody>
    <tr>
        <th scope="row"><i class="fa-solid fa-q" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;"><b>법원 비용, 변호사 보수 등 법인회생 비용은 어떻게 되나요?</b></td>
    </tr>
    <tr>
        <th scope="row"><i class="fa-solid fa-a" style="color: cornflowerblue;">.</i></th>
      <td colspan="3" style="text-align: left;">일반적으로 법원비용은 송달료, 인지내, 예납금 등이 발생하고 변호수 보수는 착수 및 성공보수가 발생합니다.</td>
    </tr>
    <tr>
    </tr>
  </tbody>
  
</table>
</template>
<script></script>
<style>
.table {
    color: black;
    width: 60%;
    border:0px solid black !important;
    margin-left:auto;margin-right:auto;
    font-size: larger  !important;
   
}

.table th{
    text-align: center;
    border-top: 0px solid white !important;
}
.table td{
    text-align: center;
    border-top: 0px solid white !important;
}
@media screen and (max-width:767px) {
/* 모바일 */
.table {
    width: 90% !important;
}}
</style>